<template>
  <div class="container">
    <Spinner />
    <div class="settings-form">
      <div class="row settings-header">
        <div class="col">
            <h4 class="text-start">
                Подключение к NotiSend
            </h4>
        </div>
      </div>
      <div class="row user-logo">
        <div class="col-3">
            <img v-if="user.photo" alt="Avatar" :src="user.photo">
        </div>
        <div class="col">
            <div class="row">
                <h5 class="text-start">
                    {{user.email}}
                </h5>
            </div>
            <div class="row">
                <h6 class="text-start">
                    {{user.organization_name}}
                </h6>
            </div>
        </div>
      </div>
      <div class="row">
          <div class="form-group row">
            <label for="secretKey" class="col-sm-2 col-form-label">Secret</label>
            <div class="col-sm-10">
                <input
                type="text"
                class="form-control"
                id="secretKey"
                placeholder="Введите секретный ключ"
                v-model="token"
                @input="workWithToken($event.target.value)"
                />
            </div>
          </div>
          <div class="row settings-button">
            <button :disabled="settingsBtnDisabled" type="submit" @click="changeTokenRequest" class="btn">Войти</button>
          </div>
      </div>
      <div class="row">
        <div class="col-2">
          <a target="_blank" href="https://blog.notisend.ru/bitrix24">Инструкция</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "../components/Spinner.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    Spinner
  },
  props: {
    page: {
        type: String,
        required: false
    }
  },
  computed: {
    ...mapGetters(["user", 'token', 'settingsBtnDisabled']),
  },
  methods: {
    ...mapMutations(['updateToken', 'settingsButtonAbled']),
    ...mapActions(['changeToken', 'doInstall']),
    workWithToken(token) {
      this.settingsButtonAbled();
      this.updateToken(token);
    },
    async changeTokenRequest() {
        await this.changeToken();
        if (this.token && this.page == 'install') {
            await this.doInstall();
            BX24.installFinish();
        }
    }
  }
}
</script>
