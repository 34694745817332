<template>
  <Settings
    :page="'install'" 
  />
</template>

<script>
import Settings from "../components/Settings.vue";

export default {
  components: {
    Settings
  }
}
</script>
